import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { authenticate, signin } from '../../api/auth';

import {
  AuthActiveButton,
  AuthContainer,
  AuthTextInput,
  AuthWrapper,
} from './AuthElements';

const Signin = () => {
  const [values, setValues] = useState({
    username: '',
    password: '',
    error: false,
    loading: false,
    redirectToReferer: false,
  });

  const { username, password, error, loading, redirectToReferer } = values;

  const handleChange =
    (name: string) => (event: React.FormEvent<HTMLInputElement>) => {
      setValues({ ...values, error: false, [name]: event.currentTarget.value });
    };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    signin({ username, password }).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error, loading: false });
      } else {
        authenticate(data, () =>
          setValues({
            ...values,
            redirectToReferer: true,
          })
        );
      }
    });
  };

  const signupForm = () => (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <AuthTextInput
        onChange={handleChange('username')}
        placeholder='uživatelské jméno'
      />
      <AuthTextInput
        onChange={handleChange('password')}
        type='password'
        placeholder='heslo'
      />
      <AuthActiveButton type='submit' onClick={handleSubmit}>
        Přihlásit se
      </AuthActiveButton>
    </form>
  );

  const showError = () => error && <p>{error}</p>;
  const showLoading = () => loading && <p>Loading...</p>;
  const redirectUser = () => {
    if (redirectToReferer) {
      return <Navigate replace to='/' />;
    }
  };

  return (
    <AuthContainer>
      <div style={{ marginTop: -80 }}>
        <img
          src={require('../../assets/images/squirell.png')}
          alt='logo'
          style={{
            height: 80,
            width: 80,
            filter: 'drop-shadow(1px 2px 2px #2d2c2c)',
          }}
        />
        <h2 style={{ color: '#fff', fontWeight: 'bold' }}>
          FERĎÁK |{' '}
          <span style={{ color: '#000', fontWeight: 'normal' }}>ADMIN</span>
        </h2>

        <AuthWrapper>
          {signupForm()}
          {showLoading()}
          {showError()}
          {redirectUser()}
          <p style={{ margin: 0, color: '#c2c2c2' }}>&#169; ferďák</p>
        </AuthWrapper>
      </div>
    </AuthContainer>
  );
};

export default Signin;
