import { API } from '../../config';

export const getList = async () => {
  try {
    const response = await fetch(`${API}/trips/list`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    });
    return await response.json();
  } catch (error: unknown) {
    return console.log(error);
  }
};
