import { useEffect, useState } from 'react';
import { getList } from '../api/trips';
import Layout from '../components/Layout/Layout';
import TripItem from '../components/TripItem/TripItem';
import { Trip } from '../components/TripItem/TripType';

const Trips = () => {
  const [trips, setTrips] = useState<Trip[]>([]);

  useEffect(() => {
    getList().then((data) => setTrips(data));
  }, []);

  trips.sort(function (a: Trip, b: Trip) {
    return new Date(b.date).valueOf() - new Date(a.date).valueOf();
  });
  return (
    <Layout title='Výlety' subtitle='Správa informací o výletech'>
      <h3>Výlety</h3>
      {trips && trips.map((trip: Trip) => <TripItem trip={trip} />)}
    </Layout>
  );
};

export default Trips;
