import React from 'react';
import { Routes as AppRoutes, Route } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import PrivateRoute from './PrivateRoute';

import Trips from '../pages/Trips';
import Signin from '../pages/Auth/Signin';
import Camps from '../pages/Camps';

const Routes = () => {
  return (
    <AppRoutes>
      <Route
        path='/'
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />

      <Route
        path='/vylety'
        element={
          <PrivateRoute>
            <Trips />
          </PrivateRoute>
        }
      />

      <Route
        path='/tabory'
        element={
          <PrivateRoute>
            <Camps />
          </PrivateRoute>
        }
      />

      <Route path='/signin' element={<Signin />} />
    </AppRoutes>
  );
};

export default Routes;
