import React, { useCallback, useEffect, useState } from 'react';
import { getStav } from '../api/camps';
import Layout from '../components/Layout/Layout';
import { COLORS } from '../styles/colors';
import { connect, useDispatch, useSelector } from 'react-redux';
import { fetchCamps } from '../store/actions/campActions';
import { useAppSelector } from '../store/store';

const Camps = (props: any) => {
  interface Turnus {
    camp: string;
    count: number;
  }
  const dispatch = useDispatch();
  const selectorCamps = useSelector((state: any) => state.camps);

  useEffect(() => {
    props.fetchCamps();
  }, []);

  const turnusNames: { [key: string]: string } = {
    f1: '1. turnus',
    f2: '2. turnus',
    f3: '3. turnus',
  };

  return (
    <Layout title='Tábory' subtitle='Správa Táborů'>
      <h3>Stav přihlášek na tábor</h3>
      <button onClick={props.fetchCamps}>Aktualizovat</button>
      <table
        style={{
          border: '1px solid',
          width: 400,
          borderCollapse: 'collapse',
        }}
      >
        <thead>
          <tr style={{ border: '1px solid', borderTopLeftRadius: 5 }}>
            <th
              style={{
                border: `1px solid ${COLORS.mainGreen}`,

                background: COLORS.mainGreen,
                color: 'white',
              }}
            >
              Turnus
            </th>
            <th
              style={{
                border: `1px solid ${COLORS.mainGreen}`,

                background: COLORS.mainGreen,
                color: 'white',
              }}
            >
              Počet
            </th>
            <th
              style={{
                border: `1px solid ${COLORS.mainGreen}`,

                background: COLORS.mainGreen,
                color: 'white',
              }}
            >
              %
            </th>
          </tr>
        </thead>
        <tbody>
          {props.camps.data &&
            props.camps.data.map((turnus: Turnus) => (
              <tr key={turnus.camp} style={{ border: '1px solid' }}>
                <td style={{ border: '1px solid' }}>
                  {turnusNames[turnus.camp]}
                </td>
                <td style={{ border: '1px solid' }}>{turnus.count}</td>
                <td style={{ border: '1px solid' }}>
                  {Math.floor((100 / 90) * turnus.count)} %
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </Layout>
  );
};

const mapStateToProps = (state: any) => ({
  camps: state.camps,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchCamps: () => dispatch(fetchCamps()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Camps);
