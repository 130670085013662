import moment from 'moment';
import React from 'react';
import { Trip } from './TripType';

interface Props {
  trip: Trip;
}

const TripItem: React.FC<Props> = ({ trip }) => {
  return (
    <div
      key={trip.id}
      style={{
        background: '#fff',
        marginBottom: 10,
        padding: 10,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 20px',
      }}
    >
      <p>ID: {trip.id}</p>
      <p>{trip.title}</p>
      <p>{moment(trip.date).format('DD. MM. YYYY')}</p>
      <p>{trip.description}</p>
    </div>
  );
};

export default TripItem;
