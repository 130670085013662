import moment from 'moment';
import React from 'react';
import InputField from '../components/InputField/InputField';
import Layout from '../components/Layout/Layout';

const Dashboard = () => {
  return (
    <Layout title='Dashboard' subtitle={moment().format('DD. M. yyyy')}>
      <InputField />
    </Layout>
  );
};

export default Dashboard;
