import axios from 'axios';
import { Dispatch } from 'redux';
import { API } from '../../config';
import { CAMPS } from './../../constants/actions';

interface LoadCampsActionType {
  type: string;
  payload?: any;
}

export type CampsActions = LoadCampsActionType;

//action creators
export const fetchCampsRequest = () => ({
  type: CAMPS.FETCH_CAMPS_REQUEST,
});

export const fetchCampsSucces = (data: any) => ({
  type: CAMPS.FETCH_CAMPS_SUCCESS,
  payload: data,
});

export const fetchCampsFailure = (error: any) => ({
  type: CAMPS.FETCH_CAMPS_FAILURE,
  payload: error,
});

export const fetchCamps = () => {
  return (dispatch: Dispatch) => {
    dispatch(fetchCampsRequest());
    axios
      .get(`${API}/camps/reg_count`)
      .then((response) => dispatch(fetchCampsSucces(response.data)))
      .catch((error) => dispatch(fetchCampsFailure(error.message)));
  };
};
