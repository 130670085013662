import Routes from './routes/Routes';

const App: React.FC = () => {
  return (
    <>
      <Routes />
    </>
  );
};

export default App;
