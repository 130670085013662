import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { signout } from '../../api/auth';
import { NavbarContainer, NavbarLink, NavbarWrapper } from './NavbarElements';
import { MdLogout, MdHouseSiding } from 'react-icons/md';

import { RiDashboardFill, RiRoadMapFill } from 'react-icons/ri';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (location: Location, path: string) => {
    if (location.pathname === path) {
      return 'true';
    } else {
      return null;
    }
  };

  return (
    <NavbarContainer>
      <NavbarWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <img
            src={require('../../assets/images/squirell.png')}
            alt='logo'
            style={{ height: 50, width: 50 }}
          />
          <h2>
            FERĎÁK | <span style={{ color: '#658354' }}>Admin</span>
          </h2>
        </div>
        {/* Menu */}
        {/* User & Active Links */}
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <div style={{ borderBottom: '1px solid #000' }}>
            {/* @ts-ignore */}
            <NavbarLink to='/' stepon={isActive(location, '/')}>
              <RiDashboardFill size={20} style={{ marginRight: 10 }} />
              <p>Dashboard</p>
            </NavbarLink>
            {/* <NavbarLink to='/profil' stepon={isActive(location, '/profil')}>
              <RiUserFill size={20} style={{ marginRight: 10 }} />
              <p>Profil</p>
            </NavbarLink> */}
          </div>
          {/* <NavbarLink to='/aktuality' stepon={isActive(location, '/aktuality')}>
            <RiFeedbackFill size={20} style={{ marginRight: 10 }} />
            <p>Aktuality</p>
          </NavbarLink> */}
          {/* <NavbarLink to='/aktivity' stepon={isActive(location, '/aktivity')}>
            <MdExtension size={20} style={{ marginRight: 10 }} />
            <p>Celoroční činnost</p>
          </NavbarLink> */}
          {/* @ts-ignore */}
          <NavbarLink to='/vylety' stepon={isActive(location, '/vylety')}>
            <RiRoadMapFill size={20} style={{ marginRight: 10 }} />
            <p>Výlety</p>
          </NavbarLink>
          {/* <NavbarLink to='/akce' stepon={isActive(location, '/akce')}>
            <RiCalendarEventFill size={20} style={{ marginRight: 10 }} />
            <p>Vícedenní akce</p>
          </NavbarLink> */}
          {/* @ts-ignore */}
          <NavbarLink to='/tabory' stepon={isActive(location, '/tabory')}>
            <MdHouseSiding size={20} style={{ marginRight: 10 }} />
            <p>Tábory</p>
          </NavbarLink>
        </div>

        {/* Foot */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            borderTop: '1px solid #000',
          }}
        >
          {/* <NavbarLink to='/nastaveni' stepon={isActive(location, '/nastaveni')}>
            <FiSettings size={19} style={{ marginRight: 10 }} />
            <p>Nastavení</p>
          </NavbarLink> */}

          <NavbarLink to='#' onClick={() => signout(() => navigate('/signin'))}>
            <MdLogout size={20} style={{ marginRight: 10 }} />
            <p>Odhlásit se</p>
          </NavbarLink>
        </div>
      </NavbarWrapper>
    </NavbarContainer>
  );
};

export default Navbar;
