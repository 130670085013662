import Navbar from '../Navbar/Navbar';

interface Props {
  title: string;
  subtitle: string;
  children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ title, subtitle, children }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
      <Navbar />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          marginLeft: 250,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
            padding: 30,
            background:
              'linear-gradient(90deg,rgba(101, 131, 84, 1) 0%,rgba(101, 131, 84, 1) 100%,rgba(108, 150, 84, 1) 49%)',
            color: '#fff',
          }}
        >
          <h1>{title}</h1>
          <h2 style={{ fontStyle: 'italic' }}>{subtitle}</h2>
        </div>
        <div style={{ flex: 1, textAlign: 'left', margin: 40 }}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
