import { ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router';
import { isAuthenticated } from '../api/auth';

interface Props {
  children: ReactElement;
}

const PrivateRoute: React.FC<Props> = ({ children }) => {
  let auth = isAuthenticated();
  let location = useLocation();

  if (!auth) {
    return <Navigate to='/signin' state={{ from: location }} />;
  }
  return children;
};

export default PrivateRoute;
