import { API } from '../../config';

type Next = () => void | Promise<void>;

export const signup = async (userData: {}) => {
  try {
    const response = await fetch(`${API}/signup`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify(userData),
    });
    return await response.json();
  } catch (error) {
    return console.log(error);
  }
};

export const signin = async (userData: {}) => {
  try {
    const response = await fetch(`${API}/signin`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify(userData),
    });
    return await response.json();
  } catch (error) {
    return console.log(error);
  }
};

export const authenticate = (data: {}, next: Next) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem('jwt', JSON.stringify(data));
    next();
  }
};

export const signout = (next: Next) => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem('jwt');
    next();
    return fetch(`${API}/signout`, {
      method: 'DELETE',
    })
      .then((response) => {
        console.log('Uživatel odhlášen!');
      })
      .catch((error) => console.log(error));
  }
};

export const isAuthenticated = () => {
  if (typeof window == 'undefined') {
    return false;
  }

  const jwt: string | null = localStorage.getItem('jwt');
  if (jwt) {
    return JSON.parse(jwt);
  } else {
    return false;
  }
};
