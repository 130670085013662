import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import campReducer from './reducers/campReducer';

export const store = configureStore({
  reducer: {
    camps: campReducer,
  },
});

console.log('Inital Camp state', store.getState().camps);

//typescript for dispatching and selecting
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<
  ReturnType<typeof store.getState>
> = useSelector;
