import React, { useRef } from 'react';

const InputField: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <form>
      <input type='input' placeholder='vypln mě' ref={inputRef} />
    </form>
  );
};

export default InputField;
