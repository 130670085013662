import { CampsActions } from './../actions/campActions';
import { CAMPS } from './../../constants/actions';

interface CampState {
  data: any[];
  loading: boolean;
  error: string;
}

const initialState: CampState = {
  data: [],
  loading: false,
  error: '',
};

const campReducer = (state: CampState = initialState, action: CampsActions) => {
  switch (action.type) {
    case CAMPS.FETCH_CAMPS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CAMPS.FETCH_CAMPS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: '',
      };
    case CAMPS.FETCH_CAMPS_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default campReducer;
