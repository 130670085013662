// @ts-nocheck
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NavbarContainer = styled.div`
  display: flex;
  position: fixed;
  height: 100vh;
  width: 250px;
  background: #fff;
  justify-content: flex-start;
  color: #000;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px;
`;

export const NavbarWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: left;
  margin: 20px;
`;

export const NavbarMenu = styled.div``;

export const NavbarLink = styled(Link)`
  color: ${({ stepon }) => (stepon ? '#658354' : '#000')};
  font-size: 18px;
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.3s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  transform-origin: 20% 100%;

  &:hover {
    transform: scale(1.1);
    color: #658354;
  }
`;
