import { COLORS } from './../../styles/colors';
import styled from 'styled-components';

export const AuthContainer = styled.div`
  background: rgb(101, 131, 84);
  background: linear-gradient(
    0deg,
    rgba(101, 131, 84, 1) 0%,
    rgba(108, 150, 84, 1) 49%,
    rgba(101, 131, 84, 1) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const AuthWrapper = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 90vw;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px;
  background: #fff;
  border-radius: 20px;
`;

export const ButtonLabel = styled.p`
  color: #658354;
  text-decoration: none;
  margin: 0;
`;

export const AuthTextInput = styled.input`
  background: transparent;
  border: 1px solid #658354;
  border-radius: 8px;
  margin-bottom: 10px;
  color: #000;
  font-size: 18px;
  padding: 10px;
  transition: 0.3s ease-in-out;

  &:focus {
    outline: none !important;
    border-bottom: 3px solid ${COLORS.mainGreen};
  }
`;

export const AuthButton = styled.div`
  background: #fff;
  font-size: 18px;

  color: #658354;
  margin: 10px;
  border: 1px solid #658354;
  border-radius: 8px;
  transition: 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

export const AuthActiveButton = styled.button`
  background: #658354;
  width: 80%;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  margin: 10px;
  padding: 10px;
  border-radius: 8px;
  border: none;
  transition: 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  &:focus {
    outline: none !important;
    border: 2px solid ${COLORS.mainGreen};
  }
`;
